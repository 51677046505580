export const STATUS = {
    ACTIVE: 1,
    INACTIVE: 2,
    REMOVED: 6
}

export const NOCHES_DE_VACIO = {
    NV1: {
        ID: 1,
        ONE: 1,
    },
    NV2: {
        ID: 2,
        TWO: 2,
    },
    NV3: {
        ID: 3,
        THREE: 3,
    },
    NV4: {
        ID: 4,
        FOUR: 4,
    },
    NV5: {
        ID: 5,
        FIVE: 5,
    },
    NV6: {
        ID: 6,
        SIX: 6,
    },
    NV7: {
        ID: 7,
        SEVEN: 7,
    },
    NV8: {
        ID: 8,
        EIGHT: 8,
    },
    NV9: {
        ID: 9,
        NINE: 9,
    },
    NV10: {
        ID: 10,
        TEN: 10,
    },
    NV11: {
        ID: 11,
        ELEVEN: 11,
    },
    NV12: {
        ID: 12,
        TWELVE: 12,
    },
    NV13: {
        ID: 13,
        THIRTEEN: 13,
    },
    NV14: {
        ID: 14,
        FOURTEEN: 14,
    },
    NV15: {
        ID: 15,
        FIFTEEN: 15,
    },
    NV0: {
        ID: 16,
        ZERO: 0,
    },
}