import {
  CloseOutlined,
  EllipsisOutlined,
  FieldTimeOutlined,
  FilterOutlined,
  PlusCircleOutlined
} from '@ant-design/icons'
import { Badge, Button, Dropdown, Menu, Space, Table, Tooltip } from 'antd'
import { AppContext } from 'app/AppContext'
import { NIVEL, SECTOR, ZONA } from 'app_permissions/piramides'
import axios from 'axios'
import { PrimaryButton, SecondaryButton } from 'components/common/Buttons'
import CustomSearch from 'components/common/Forms/CustomSearch'
import {
  AlertModal,
  ChangeStatusModal,
  DeleteModal
} from 'components/common/modals/modals'
import DetalleDrawer from 'components/Drawer/DetalleDrawer'
import FilterDrawer from 'components/Drawer/FilterDrawer'
import PyramidHistoryDrawer from 'components/Drawer/HistoryDrawer'
import { STATUS } from 'constants/types/types'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { capitalize } from 'utils/Capitalize'
import EncryptBase64 from 'utils/EncryptBase64'
import { handleError } from 'utils/handleApiResponse'
import { userHasPermission } from 'utils/userHasPermission'
import { useSectores } from 'utils/UseSectores'

const PiramidesPollos = ({ specieId, specieName }) => {
  const navigate = useNavigate()
  const [isFilterVisible, setIsFilterVisible] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState(null)
  const [isDetalleVisible, setIsDetalleVisible] = useState(false)
  const [isHistoryVisible, setIsHistoryVisible] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const {
    handleSearch,
    isSearching,
    isDataFetching,
    deleteSector,
    isRecordDeleting,
    getSectores,
    sectores,
    total,
    limitRows,
    setLimitRows,
    handleFilters,
    isFiltering,
    setIsFiltering,
    toggleRefresh,
    setToggleRefresh,
    SetSpecieIdSelect,
  } = useSectores();

  const [statusChange, setStatusChange] = useState({
    title: '',
    message: '',
    structure: '',
    isRecordUpdating: false,
    payload: {}
  })
  const [isChangeStatusModalOpen, setIsChangeStatusModalOpen] = useState(false)
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [alert, setAlert] = useState({
    alertType: '',
    title: '',
    message: '',
    errors: []
  })
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [deleteModal, setDeleteModal] = useState({})
  const [deletePayload, setDeletePayload] = useState({})
  const [searchValue, setSearchValue] = useState('')
  const [filterValue, setFilterValue] = useState('')
  const { encodeBase64 } = EncryptBase64()
  const [shouldReset, setShouldReset] = useState(false)

  const { rolData } = useContext(AppContext)

  const CREAR_NIVEL = userHasPermission(rolData?.role, NIVEL.CREAR_NIVEL)
  const CREAR_ZONA = userHasPermission(rolData?.role, ZONA.CREAR_ZONA)
  const CREAR_SECTOR = userHasPermission(rolData?.role, SECTOR.CREAR_SECTOR)

  const CREAR_PIRAMIDE = CREAR_NIVEL || CREAR_ZONA || CREAR_SECTOR

  /**
   * @description Change a status sector with modal
   * @param {*} record
   */
  const showChangeStatusModal = async (record) => {
    if (parseInt(record.status_id) === STATUS.ACTIVE) {
      setStatusChange((prev) => ({
        title: 'Desactivar Sector',
        message: `¿Estás seguro/a que quieres desactivar el sector ${record.name}?`,
        payload: {
          sectors: [
            {
              config_id: record.id,
              specie_id: specieId,
              sector_id: record.id,
              status_id: STATUS.INACTIVE,
              user: rolData.id,
              aproved_check: true
            }
          ]
        }
      }))
      setIsChangeStatusModalOpen(true)
    } else {
      setStatusChange((prev) => ({
        title: 'Activar Sector',
        message: `¿Estás seguro/a que quieres activar el sector ${record.name}?`,
        payload: {
          sectors: [
            {
              config_id: record.id,
              specie_id: specieId,
              sector_id: record.id,
              status_id: STATUS.ACTIVE,
              user: rolData.id,
              aproved_check: true
            }
          ]
        }
      }))
      setIsChangeStatusModalOpen(true)
    }
  }

  /**
   * @description Change status sector
   */
  const changeStatus = async () => {
    setStatusChange((prev) => ({ ...prev, isRecordUpdating: true }))
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL_SECTOR}/sector/more-options/status-change`,
        statusChange.payload
      )
      if (data.length > 0) {
        setAlert({
          alertType: 'success',
          title: '¡Cambio de estado exitoso!',
          message: `El sector ${data[0][0].name} ha sido ${
            data[0][0].status_id === '1' ? 'activado' : 'desactivado'
          } de manera exitosa`
        })
        setIsAlertOpen(true)
        handleFilter({
          specieId: specieId,
          keyword: searchValue,
          level: filterValue.level,
          type_establishment: filterValue.type_establishment,
          regions: filterValue.regions,
          currentPageFilter: currentPage,
          isFilter: isFiltering
        })
      }
    } catch (error) {
      setAlert({
        alertType: 'error',
        title: '¡Cambio de estado no exitoso!',
        errors: [error?.response?.data?.error]
      })
      setIsAlertOpen(true)
    }
    setStatusChange((prev) => ({ ...prev, isRecordUpdating: false }))
    setIsChangeStatusModalOpen(false)
  }

  const showDeleteModal = (record) => {
    setDeleteModal({
      message: `¿Estás seguro/a que quieres eliminar el sector ${record.name}?`,
      structureName: record.name
    })
    setDeletePayload({
      config_id: parseInt(record.id),
      specie_id: specieId,
      sector_id: parseInt(record.id),
      user: rolData.id
    })
    setIsDeleteModalOpen(true)
  }

  /**
   * @description Confirm delete a sector
   * @param {*} record
   */
  const confirmDeleteSector = async (record) => {
    const response = await deleteSector(deletePayload, record.name)
    if (response === 201) {
      setAlert({
        alertType: 'success',
        title: '¡Cambio de estado exitoso!',
        message: `El sector ha sido eliminado exitosamente.`
      })
    }
    if (isFiltering) {
      handleFilter({
        specieId: specieId,
        keyword: searchValue,
        level: filterValue.level,
        type_establishment: filterValue.type_establishment,
        regions: filterValue.regions,
        currentPageFilter: currentPage,
        isFilter: isFiltering
      })
    }
    setIsDeleteModalOpen(false)
    setIsAlertOpen(true)
  }

  const handleResetFilters = () => {
    setSearchValue('')
    setCurrentPage(1)
    setShouldReset(true)
  }

  useEffect(() => {
    SetSpecieIdSelect(specieId);
  }, [specieId]);

  const menu = (record) => (
    <Menu
      items={[
        {
          key: '1',
          label: (
            <p onClick={() => showChangeStatusModal(record)}>
              {Number(record.status_id) === 1 ? 'Desactivar' : 'Activar'}
            </p>
          )
        },
        {
          key: '2',
          label: <p onClick={() => showDeleteModal(record)}>Eliminar</p>
        },
        {
          key: '3',
          label: (
            <p
              onClick={() =>
                navigate(
                  `/piramides/editar/sector/${specieName}/${encodeBase64(
                    record.id
                  )}`,
                  {
                    state: {
                      specieId: specieId
                    }
                  }
                )
              }
            >
              Editar sector
            </p>
          )
        }
      ]}
    />
  )

  /**
   * @description Handler filters with search or paginator
   * @param {*} Data that to belong to values to filter or data from paginator filter
   */
  const handleFilter = (data) => {
    switch (data.remove) {
      case true:
        setCurrentPage(1)
        setIsFiltering(false)
        setFilterValue('')
        getSectores(specieId)
        setIsFilterVisible(false)
        break
      default:
        setFilterValue(data)
        setIsFiltering(data.isFilter ?? true)
        setCurrentPage(data.currentPageFilter)
        handleFilters(
          specieId,
          data.keyword,
          data.level,
          data.type_establishment,
          data.regions,
          data.currentPageFilter
        )
        setIsFilterVisible(false)
        break
    }
  }

  const status = {
    1: 'success',
    2: 'error'
  }

  const columns = [
    {
      key: '1',
      title: 'Nivel',
      dataIndex: ['level', 'name'],
      render: (record) => (record ? capitalize(record) : '')
    },
    {
      key: '2',
      title: 'Sector',
      render: (record) => {
        return (
          <span>
            <Badge status={status[parseInt(record?.status_id)]} />
            {record?.name ? capitalize(record.name) : 'n/a'}
          </span>
        )
      }
    },
    {
      key: '3',
      title: 'Centro SAP',
      dataIndex: ['code_sap']
    },
    {
      key: '4',
      title: 'Establecimiento',
      dataIndex: ['typeEstablished', 'name'],
      render: (record) => (record ? capitalize(record) : '')
    },
    {
      key: '5',
      title: 'Zona',
      dataIndex: ['zone', 'name'],
      render: (record) => (
        <Tooltip title={record}>{record ? capitalize(record) : ''}</Tooltip>
      )
    },
    {
      key: '7',
      title: 'Comuna',
      dataIndex: ['address', 'commune', 'name'],
      render: (record) => (record ? capitalize(record) : null)
    },
    {
      key: '8',
      title: 'Región',
      dataIndex: ['address', 'region', 'name'],
      render: (record) => (record ? capitalize(record) : null)
    },
    {
      title: 'Detalle',
      render: (text, record) => (
        <span>
          <SecondaryButton
            onButtonClick={() => {
              setSelectedRecord(record)
              setIsDetalleVisible(true)
            }}
          >
            Ver detalle
          </SecondaryButton>
        </span>
      )
    },
    {
      title: 'Acción',
      render: (_, record) => {
        return (
          <div className='action'>
            <Dropdown overlay={menu(record)} trigger={['click']}>
              <Space>
                <EllipsisOutlined rotate={90} />
              </Space>
            </Dropdown>
          </div>
        )
      }
    }
  ]

  useEffect(() => {
    if (!isFiltering) {
      getSectores(specieId)
    }
  }, [toggleRefresh])

  const closeAlertModal = () => {
    setIsAlertOpen(false)
    setToggleRefresh(!toggleRefresh)
  }

  return (
    <div className='piramides'>
      <div className='piramides-wrapper'>
        <div className='piramides-header'>
          <div className='piramides-filters'>
            <CustomSearch
              loading={isSearching || isDataFetching}
              disabled={isSearching || isDataFetching}
              value={searchValue}
              placeholder='Ingrese sector o Centro SAP...'
              onSearch={(value) => {
                if (!isFiltering) {
                  setCurrentPage(1)
                  handleSearch(searchValue, specieId, 1)
                }
                handleFilter({
                  ...filterValue,
                  keyword: searchValue,
                  currentPageFilter: 1,
                  isFilter: true
                })
              }}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <div className='piramides-filters-sm'>
              <Tooltip placement='bottom' title='Filtros'>
                <Button
                  onClick={() => setIsFilterVisible(true)}
                  className='iconButton'
                  loading={isSearching || isDataFetching}
                >
                  {<FilterOutlined />}
                </Button>
              </Tooltip>
            </div>
            <div className='piramides-filters-lg'>
              <SecondaryButton onButtonClick={() => setIsFilterVisible(true)}>
                Filtros
              </SecondaryButton>
            </div>
            {isFiltering ? (
              <PrimaryButton
                className='mobile-filters'
                onButtonClick={handleResetFilters}
              >
                <CloseOutlined />
              </PrimaryButton>
            ) : null}
          </div>
          {CREAR_PIRAMIDE && (
            <div className='piramides-actions'>
              <div className='piramides-actions-sm'>
                <Tooltip placement='bottom' title='Historial'>
                  <Button
                    onClick={() => setIsHistoryVisible(true)}
                    className='iconButton'
                    icon={<FieldTimeOutlined />}
                  />
                </Tooltip>
                <Tooltip placement='bottom' title='Crear nuevo'>
                  <Button
                    onClick={() => navigate('/piramides/crear/pollo')}
                    className='iconButton'
                    icon={<PlusCircleOutlined />}
                  />
                </Tooltip>
              </div>
              <div className='piramides-actions-lg'>
                <SecondaryButton
                  onButtonClick={() => setIsHistoryVisible(true)}
                  width={150}
                  icon={<FieldTimeOutlined />}
                >
                  Historial
                </SecondaryButton>
                <PrimaryButton
                  onButtonClick={() => navigate('/piramides/crear/pollo')}
                  icon={<PlusCircleOutlined />}
                  width={180}
                >
                  Crear nuevo
                </PrimaryButton>
              </div>
            </div>
          )}
        </div>
        <div className='piramides-table'>
          <Table
            loading={isDataFetching || isSearching}
            columns={columns}
            dataSource={sectores}
            scroll={{ x: 'max-content' }}
            pagination={{
              pageSize: limitRows,
              onShowSizeChange: (current, size) => {
                setLimitRows(size)
              },
              current: currentPage,
              total: total,
              showTotal: (total, range) =>
                `${range[0]} a ${range[1]} de ${total} resultados`
            }}
            onChange={(selection) => {
              if (!isFiltering) {
                handleSearch(
                  searchValue,
                  specieId,
                  selection.current,
                  selection.pageSize
                )
              }
              handleFilter({
                specieId: specieId,
                keyword: searchValue,
                level: filterValue.level,
                type_establishment: filterValue.type_establishment,
                regions: filterValue.regions,
                currentPageFilter: selection.current,
                isFilter: isFiltering
              })
            }}
          />
        </div>
      </div>
      <FilterDrawer
        specieId={specieId}
        visible={isFilterVisible}
        onClose={() => setIsFilterVisible(false)}
        filterResults={handleFilter}
        searchValue={searchValue}
        shouldReset={shouldReset}
        setShouldReset={setShouldReset}
        setIsFiltering={setIsFiltering}
      />
      <PyramidHistoryDrawer
        visible={isHistoryVisible}
        onClose={() => setIsHistoryVisible(false)}
        specieId={specieId}
      />
      <DetalleDrawer
        visible={isDetalleVisible}
        onClose={() => setIsDetalleVisible(false)}
        record={selectedRecord}
      />
      <DeleteModal
        show={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        messageModal={deleteModal.message}
        isLoading={isRecordDeleting}
        onConfirm={confirmDeleteSector}
        title='Eliminar Sector'
        structureName={deleteModal.strcutureName}
      />
      <ChangeStatusModal
        title={statusChange.title}
        show={isChangeStatusModalOpen}
        messageModal={statusChange.message}
        structureName={statusChange.structure}
        isLoading={statusChange.isRecordUpdating}
        onClose={() => setIsChangeStatusModalOpen(false)}
        onConfirm={changeStatus}
      />
      <AlertModal
        show={isAlertOpen}
        onClose={closeAlertModal}
        type={alert.alertType}
        title={alert.title}
        errorMessages={alert.errors}
      >
        {alert.message}
      </AlertModal>
    </div>
  )
}

export default PiramidesPollos
