import { CloseOutlined, FullscreenOutlined } from '@ant-design/icons'
import { Table, Tooltip } from 'antd'
import { PrimaryButton, SecondaryButton } from 'components/common/Buttons'
import {
  AlimentoIcon,
  CerdoIcon,
  PavoIcon,
  PolloIcon
} from 'components/common/Icons/Icons'
import moment from 'moment'
import React, { useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ModalRegistroMovimiento from './ModalRegistroMovimientos'
import { useMovimientos } from './hooks/useMovimientos'
import { capitalize } from 'lodash'
import FiltroTablaMovimientos from 'components/Drawer/FiltroTablaMovimientos'
import CustomSearch from 'components/common/Forms/CustomSearch'
import HistorialDrawer from 'components/Drawer/HistorialDrawer'
import { AppContext } from 'app/AppContext'
import { userHasPermission } from 'utils/userHasPermission'
import { MOVIMIENTOS_USUARIOS } from 'app_permissions/dashboard'

function TablaRegistroMovimientosUsuario({ setIsLoading }) {
  const { state } = useLocation()
  const { identificador, type_identificador } = state
  const [modalRegistro, setModalRegistro] = useState(false)
  const [modalInfo, setModalInfo] = useState({
    type: '',
    title: '',
    message: ''
  })

  const [showModal, setShowModal] = useState(false)
  const [showDHistory, setshowDHistory] = useState(false)
  const [showDrawer, setShowDrawer] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [limitRows, setLimitRows] = useState(10)

  const {
    handleSearch,
    tableData,
    isSearching,
    isDataFetching,
    setIsDataFetching,
    total,
    isFiltering,
    setIsFiltering,
    fetchConfig,
    setFetchConfig,
    filterByUser,
    filterValue,
    setFilterValue
  } = useMovimientos(identificador, type_identificador)

  const [shouldReset, setShouldReset] = useState(false)
  const [errors, setErrors] = useState([])

  const { rolData } = useContext(AppContext)

  const CREAR = userHasPermission(rolData?.role, MOVIMIENTOS_USUARIOS.CREAR)

  const openFilterDrawer = () => {
    setShowDrawer(true)
  }
  const onClose = () => {
    setShowDrawer(false)
  }

  const showDrawerHistorial = () => {
    setshowDHistory(true)
  }

  const closeDrawerHistorial = () => {
    setshowDHistory(false)
  }

  const handleResetFilters = () => {
    setFilterValue({
      area: filterByUser?.area ?? [],
      startDt: undefined,
      endDt: undefined,
      status: [],
      keyword: '',
      sector: filterByUser?.sector ?? [],
      porteria: filterByUser?.porterias ?? [],
      plants: filterByUser?.plants ?? []
    })
    setSearchValue('')
    setFetchConfig({
      ...fetchConfig,
      offset: 1,
      limit: 10
    })
    setShouldReset(true)
  }

  const areaIcon = {
    pollos: <PolloIcon bgcolor='rgba(232, 119, 34, 0.1)' />,
    pavos: <PavoIcon bgcolor='rgba(232, 119, 34, 0.1)' />,
    cerdos: <CerdoIcon bgcolor='rgba(232, 119, 34, 0.1)' />,
    planta: <AlimentoIcon bgcolor='rgba(232, 119, 34, 0.1)' />
  }

  const openModalMovement = () => {}

  const statusCodes = {
    1: 'Activo',
    2: 'Denegado',
    3: 'Pendiente',
    4: 'Aprobado',
    5: 'Denegado',
    7: 'Aprobado',
    8: 'Denegado',
    9: 'Finalizado',
    10: 'Aprobado'
  }

  const formatDate = (dateTimestamp) => {

    const formattedDate = new Date(Number(dateTimestamp)).toLocaleString('es-CL', {
      timeZone: 'America/Santiago',
      hour12: false,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
    
    const dateParts = formattedDate.split(', ');  
    const date = dateParts[0];  
    const time = dateParts[1];  
    
    const customFormattedDate = `${date} a las ${time} hrs`;
    
   return customFormattedDate;
    
    
  }

  /**
   * @description Handler filters with search or paginator
   * @param {*} Data that to belong to values to filter or data from paginator filter
   */

  const handleFilterValues = (data) => {
    switch (data.remove) {
      case true:
        setFetchConfig({
          ...fetchConfig,
          offset: 1,
          limit: 10
        })
        setIsFiltering(false)
        setFilterValue({
          area: filterByUser?.area ?? [],
          startDt: undefined,
          endDt: undefined,
          status: [],
          keyword: '',
          sector: filterByUser?.sector ?? [],
          porteria: filterByUser?.porterias ?? [],
          plants: filterByUser?.plants ?? []
        })
        setShowDrawer(false)
        break
      default:
        setFilterValue(data)
        setIsFiltering(data.isFilter ?? true)
        setFetchConfig({
          ...fetchConfig,
          offset: 1,
          limit: 10
        })
        setShowDrawer(false)
        break
    }
  }

  const columns = [
    {
      title: 'Área',
      dataIndex: 'area',
      key: 'area',
      render: (record) => {
        const name = record.toLowerCase().split(' ')
        return <Tooltip title={record}>{areaIcon[name[0]]}</Tooltip>
      }
    },
    {
      title: 'Sector',
      render: (record) => capitalize(record.sector ?? '-')
    },
    {
      title: 'Fecha Ingreso',
      dataIndex: 'entryAt',
      key: 'entryAt',
      render: (entryAt) => formatDate(entryAt)
    },
    {
      title: 'Fecha Salida',
      dataIndex: 'exitAt',
      key: 'exitAt',
      render: (exitAt) => (exitAt ? formatDate(exitAt) : '-')
    },
    {
      title: 'Estado',
      dataIndex: 'status_id',
      key: 'status_id',
      render: (status_id) => <span>{statusCodes[status_id]}</span>
    },
    {
      title: 'Razón',
      dataIndex: 'reason',
      key: 'reason'
    }
  ]

  const handlePaginationChange = ({ current, pageSize }, _) => {
    setFetchConfig({
      ...fetchConfig,
      limit: pageSize,
      offset: current
    })
  }

  return (
    <div className='registro-movimientos-table'>
      <div className='filter-container' style={{ backgroundColor: '#fff' }}>
        <div className='filters'>
          <div className='search-filter'>
          </div>
          <div className='drawer-filter-btn'>
          </div>
          {isFiltering ? (
            <PrimaryButton
              className='mobile-filters'
              onButtonClick={handleResetFilters}
            >
              <CloseOutlined />
            </PrimaryButton>
          ) : null}
        </div>
        {CREAR && (
          <div
            className='register-movement-btn'
            style={{
              display: 'flex',
              gap: '5px'
            }}
          >
            <SecondaryButton onButtonClick={showDrawerHistorial}>
              Historial
            </SecondaryButton>
            <PrimaryButton
              onButtonClick={() => setModalRegistro(true)}
              identification={identificador}
              icon={<FullscreenOutlined />}
            >
              Registrar movimiento
            </PrimaryButton>
          </div>
        )}
      </div>
      <Table
        loading={isDataFetching || isSearching}
        columns={columns}
        dataSource={tableData}
        scroll={{ x: 'max-content' }}
        pagination={{
          showSizeChanger: true,
          pageSize: fetchConfig.limit,
          current: fetchConfig.offset,
          total: total,
          showTotal: (total, range) =>
            `${range[0]} a ${range[1]} de ${total} resultados`
        }}
        onChange={handlePaginationChange}
      />
      <FiltroTablaMovimientos
        onClose={onClose}
        visible={showDrawer}
        handleFilterValues={handleFilterValues}
        isSearching={isSearching}
        isDataFetching={isDataFetching}
        searchValue={searchValue}
        shouldReset={shouldReset}
        setShouldReset={setShouldReset}
        setIsFiltering={setIsFiltering}
        filterByUser={filterByUser}
        setShowDrawer={setShowDrawer}
        setIsDataFetching={setIsDataFetching}
        setLoadingTarget={setIsLoading}
      />
      <ModalRegistroMovimiento
        modalRegistro={modalRegistro}
        setModalRegistro={setModalRegistro}
        setModalInfo={setModalInfo}
        setShowModal={setShowModal}
        setErrors={setErrors}
        setFetchConfig={setFetchConfig}
        fetchConfig={fetchConfig}
      />
      <HistorialDrawer
        onClose={closeDrawerHistorial}
        isVisible={showDHistory}
        type='MovementsByUser'
        rut={identificador}
        type_identificador={type_identificador}
      />
    </div>
  )
}

export default TablaRegistroMovimientosUsuario
