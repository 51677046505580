import {
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  ConfigProvider,
  Spin,
  AutoComplete,
} from "antd";
import { PrimaryButton } from "components/common/Buttons";
import React, { useContext, useEffect, useState } from "react";
import useWindowSize from "utils/useWindowSize";
import es_ES from "antd/lib/locale/es_ES";
import Permisos from "./Permisos";
import axios from "axios";
import { capitalize } from "utils/Capitalize";
import { AlertModal } from "components/common/modals/modals";
import { useRut } from "utils/FormatRut";
import { useCountryCodes } from "utils/PhonePrefixes";
import { Navigate, useNavigate } from "react-router-dom";
import { handleError } from "utils/handleApiResponse";
import dataEnterprice from "./DataEnterprice";
import PermissionsAccess from "./PermissionsAccess";
import PermisosPlantas from "./Permisos";
import { AppContext } from "app/AppContext";
import { userHasPermission } from "utils/userHasPermission";
import { COLABORADORES } from "app_permissions/usuarios";
import useCountries from "utils/useCountries";
import useCargos from "utils/useCargos";

const { RangePicker } = DatePicker;

const CrearUsuario = (props) => {
  const navigate = useNavigate();
  const { windowWidth } = useWindowSize();
  const [form] = Form.useForm();
  const [specieType, setSpecieType] = useState("");
  const [selectedDates, setSelectedDates] = useState("");
  const [cerdoData, setCerdoData] = useState(null);
  const [pavoData, setPavoData] = useState(null);
  const [polloData, setPolloData] = useState(null);
  const [alimentosData, setAlimentosData] = useState(null);
  const [personalData, setPersonalData] = useState(null);
  const [genderData, setGenderData] = useState(null);
  const [nationalityData, setNationalityData] = useState(null);
  const [issuingEntityData, setIssuingEntityData] = useState(null);

  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState();
  const [alertTitle, setAlertTitle] = useState();
  const [alertMessage, setAlertMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [radioValue, setRadioValue] = useState(1);
  const { formatPhone, setWithLabel, sortedCodesWithLabel } = useCountryCodes();
  const { validate, format, clean } = useRut("");
  const [isSpeciesLoading, setIsSpeciesLoading] = useState(true);
  const [errorSpecie, setErrorSpecie] = useState("none");
  const [species, setSpecies] = useState({
    cerdos: null,
    pollos: null,
    pavos: null,
    plantaalimentos: null,
  });

  const { countries } = useCountries();
  const { cargoColaborador } = useCargos();

  const [errors, setErrors] = useState([]);

  const [disableCompany, setDisableCompany] = useState(false);
  const [disableSocialReasonCompany, setDisableSocialReasonCompany] =
    useState(false);

  const { rolData } = useContext(AppContext);

  const validateInput = (rule, value) => {
    const regex = /^\d+$/;
    if (value && !value.match(regex)) {
      return Promise.reject("Solo se permiten números");
    }
    return Promise.resolve();
  };

  const specieTypeBtns = [
    {
      btnType: "button",
      btnText: "Cerdo",
      value: "cerdo",
      id: 1,
    },
    { btnType: "button", btnText: "Pavo", value: "pavo", id: 2 },
    { btnType: "button", btnText: "Pollo", value: "pollo", id: 3 },
    { btnType: "button", btnText: "Alimentos", value: "alimento", id: 4 },
  ];

  const handleChange = (e) => {
    if (e.target.value === "") {
      form.setFieldsValue({ rut: "" });
      return;
    }
    form.setFieldsValue({ rut: format(e.target.value) });
  };

  const handleChangeCompanyRut = (e) => {
    if (e.target.value === "") {
      form.setFieldsValue({
        company: {
          rut: "",
        },
      });
      form.setFieldsValue({
        company: {
          social_reason: "",
        },
      });
      setDisableCompany(false);
      return;
    }

    const parameterFormat = format(e.target.value);
    const filterEqualRutCompany = dataEnterprice.filter((item) => {
      const parts = item.rutCompany;
      return parts === parameterFormat;
    });

    if (filterEqualRutCompany.length >= 1) {
      form.setFieldsValue({
        company: {
          social_reason: filterEqualRutCompany[0].nameCompany,
        },
      });
      setDisableCompany(true);
    }

    form.setFieldsValue({
      company: {
        rut: format(e.target.value),
      },
    });
  };

  const handleChangeCompanySocialReason = (e) => {
    if (e.target.value === "") {
      form.setFieldsValue({
        company: {
          rut: "",
        },
      });
      form.setFieldsValue({
        company: {
          social_reason: "",
        },
      });
      setDisableSocialReasonCompany(false);
      return;
    }

    const filterEqualNameCompany = dataEnterprice.filter((item) => {
      const parts = item.nameCompany;
      return (
        parts.toLocaleLowerCase().trim() ===
        e.target.value.toLocaleLowerCase().trim()
      );
    });

    if (filterEqualNameCompany.length >= 1) {
      form.setFieldsValue({
        company: {
          rut: filterEqualNameCompany[0].rutCompany,
        },
      });
      setDisableSocialReasonCompany(true);
    }

    form.setFieldsValue({
      company: {
        social_reason: e.target.value,
      },
    });
  };


  /**
   *  metodo que valida y persiste el usuario en la BD. utilizando el servicio `${process.env.REACT_APP_BASE_URL_REQUESTS}/requests`
   * entregando como parametros los datos ingresados en el formulario.
   * @param {} values, Values from formulario
   */
  const crearUsuario = async (values) =>{

    let permission_access = [];

    const hasVerifyCode = values?.rut?.toLocaleLowerCase().includes("k");
    const hasVerifyCodeCompany = values?.company?.rut
      ?.toLocaleLowerCase()
      .includes("k");

    if (values.rut) {
      values.rut = parseInt(clean(values.rut));
    }
    values.has_code_rut_verify = hasVerifyCode?.toString();

    if (values.company.rut) {
      values.company.rut = parseInt(clean(values.company.rut));
    }
    values.company.has_code_rut_verify_company =
      hasVerifyCodeCompany.toString();
    values.company.social_reason = capitalize(values.company.social_reason);
    if (values.company.phone_company)
      values.company.phone_company = formatPhone(
        values.company.phone_company,
        values.companyPhonePrefix
      );

    if (values.phone_number)
      values.phone_number = formatPhone(
        values.phone_number,
        values.phonePrefix
      );

    values.code_entity_issue = "IITT";

    if (cerdoData) {
      permission_access.push(cerdoData);
    }
    if (polloData) {
      permission_access.push(polloData);
    }
    if (pavoData) {
      permission_access.push(pavoData);
    }
    if (alimentosData) {
      permission_access.push(alimentosData);
    }

    values.permission_access = permission_access;

    delete values.plants;

    if (permission_access.length === 0) {
      setErrorSpecie("block");
      setIsLoading(false);
    } else {
      setErrorSpecie("none");
       await axios
         .post(`${process.env.REACT_APP_BASE_URL_REQUESTS}/requests`, values)
         .then((response) => {
           form.resetFields();
           setPavoData(null);
           setPolloData(null);
           setCerdoData(null);
           setAlimentosData(null);
           setSpecieType("");
           setAlertType("success");
           setAlertTitle("Éxito");
           setAlertMessage("Usuario creado exitosamente");
         })
         .catch((error) => {
           setErrors(handleError(error));
           setAlertType("error");
           setAlertTitle("Error");
          setSpecieType("");
           setPavoData(null);
           setPolloData(null);
           setCerdoData(null);
           setAlimentosData(null);
         })
         .finally(() => {
         setIsLoading(false);
           setShowAlert(true);
           setPavoData(null);
           setPolloData(null);
           setCerdoData(null);
           setAlimentosData(null);
           setSpecieType("");
           form.resetFields();
           setIsLoading(false);
         });
    }
  }

  /**
   *  validacion de solicitudes del usuario, para frenar 
   * la creacion de nuevos usuarios que cuenten con una solictud abierta
   * 
   * @param {*} values, Values from formulario
   * 
   * si el servicio determina que el usuario cuenta con alguna solicitud en proceso corta el flujo del nuevo usuario
   * 
   */
  const handleSubmit = async (values) => {
    setIsLoading(true);
    axios.get(`${process.env.REACT_APP_BASE_URL_REQUESTS}/requests/findUser/`.concat(values.rut))
      .then((response) => {

        const estadoSol = true;
        if (response.status == 200) {
          if(  response.data.data != null && response.data.data.solicitudes!=undefined){
            const solicitudes = response.data.data.solicitudes;
            if(solicitudes.salidaAPVD.cantidad>0){
              setAlertType("error");
              setAlertTitle("Error");
              setAlertMessage(`El usuario ya cuenta con una solicitud aprobada`);
              setShowAlert(true);
            }
            else{
              // TODO: se genera la solicitud al no tener una aprovada
               crearUsuario(values);// creacion del usuario en BD
            }
          }
          else{
              //TODO: se genera el nuevo usuario, ya que no se encontraron solicutdes realizadas 
              // ! se debe modificar el servicio creado en back para solamente enviar las solicitudes aprovadas
            crearUsuario(values);// creacion del usuario en BD
          }
        }
      })
 
    
  };

  const getIssuingEntityData = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/issuing-entity`
      )
      .then((response) => {
        setIssuingEntityData(response.data);
      })
      .catch((error) => {
        console.error(error, "error issuing-entity");
      });
  };

  const formatDate = (date) => {
    const splitDate = date.split("/");
    const formattedDate = `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`;
    return formattedDate;
  };

  const selectDate = (value, dateString) => {
    //clear selectedDates when datepicker is cleared
    if (value === null) {
      setSelectedDates("");
      setSpecieType({ value: "", component: null });
      return;
    }
    const date_start = formatDate(dateString[0]);
    const date_end = formatDate(dateString[1]);
    setSelectedDates({ date_start: date_start, date_end: date_end });
  };

  const getCerdoData = (data) => {
    if (data.length === 0) {
      setCerdoData(null);
    } else if (data.all) {
      setCerdoData({
        code_specie: species.cerdos.code,
        all: data.all,
      });
    } else {
      setCerdoData({
        code_specie: species.cerdos.code,
        levels: data,
      });
    }
  };

  const getPavoData = (data) => {
    if (data.length === 0) {
      setPavoData(null);
    } else if (data.all) {
      setPavoData({
        code_specie: species.pavos.code,
        all: data.all,
      });
    } else {
      setPavoData({
        code_specie: species.pavos.code,
        levels: data,
      });
    }
  };

  const getPolloData = (data) => {
    if (data.length === 0) {
      setPolloData(null);
    } else if (data.all) {
      setPolloData({
        code_specie: species.pollos.code,
        all: data.all,
      });
    } else {
      setPolloData({
        code_specie: species.pollos.code,
        levels: data,
      });
    }
  };

  const getAlimentoData = (data) => {
    if (data.length === 0) {
      setAlimentosData(null);
    } else {
      setAlimentosData({
        code_specie: species.plantaalimentos.code,
        plants: data,
      });
    }
  };

  const getTypePersonal = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/type-personal`
      )
      .then((response) => {
        setPersonalData(response.data);
      })
      .catch((error) => {
        console.error(error, "error");
      });
  };

  const getGenders = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/gender`)
      .then((response) => {
        setGenderData(response.data);
      })
      .catch((error) => {
        console.error(error, "error");
      });
  };

  const getNationalities = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/nationality`)
      .then((response) => {
        const sortedCountries = response.data
          .reduce((uniqueCountries, country) => {
            if (!uniqueCountries.some(({ name }) => name === country.name)) {
              uniqueCountries.push({
                ...country,
                name: capitalize(country.name),
              });
            }
            return uniqueCountries;
          }, [])
          .sort((a, b) => {
            if (a.name === "Chilena") return -1;
            if (b.name === "Chilena") return 1;
            return a.name.localeCompare(b.name);
          });

        setNationalityData(sortedCountries);
      })
      .catch((error) => {
        console.error(error, "error");
      });
  };

  useEffect(() => {
    const getSpecies = async () => {
      setIsSpeciesLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/species`
        );

        const newData = response.data.reduce((obj, item) => {
          obj[item.name_specie.replace(" ", "").toLowerCase()] = item;
          return obj;
        }, {});

        setSpecies(newData);
        setIsSpeciesLoading(false);
      } catch (error) {
        console.error(error);
        setIsSpeciesLoading(false);
      }
    };
    setWithLabel(true);
    getTypePersonal();
    getGenders();
    getNationalities();
    getIssuingEntityData();
    getSpecies();
  }, []);

  function onFinishFailed(errorInfo) {
    if (errorInfo.errorFields && errorInfo.errorFields.length > 0) {
      const firstErrorField = errorInfo.errorFields[0];
      let id = "";
      if (firstErrorField.name.length > 1) {
        for (let i = 0; i < firstErrorField.name.length; i++) {
          if (i === firstErrorField.name.length - 1) {
            id = id + firstErrorField.name[i];
          } else {
            id = id + firstErrorField.name[i] + "_";
          }
        }
      } else {
        id = firstErrorField.name;
      }

      const input = document.querySelector(`[id="${id}"]`);

      if (input) {
        input.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }

  const validateRut = (_, value) => {
    if (value === "" || value === undefined) return Promise.resolve();
    const valid = validate(value);
    if (!valid) {
      return Promise.reject("Rut inválido");
    }
    return Promise.resolve();
  };

  const rulesRut = [
    { required: true, message: "Campo requerido" },
    {
      validator: validateRut,
      message: "Debes ingresar un RUT válido",
    },
  ];

  const rules = [{ required: true, message: "Campo requerido" }];

  const rulesPhone = [
    { required: false, message: "Campo requerido" },
    { validator: validateInput, message: "Debes ingresar un teléfono válido" },
    {
      max: form.getFieldValue("phonePrefix") === "+56" ? 9 : 20,
      message: "El número de teléfono no puede tener más de 9 dígitos",
    },
  ];

  const rulesCompanyPhone = [
    { required: false, message: "Campo requerido" },
    { validator: validateInput, message: "Debes ingresar un teléfono válido" },
    {
      max: form.getFieldValue("phonePrefix") === "+56" ? 9 : 20,
      message: "El número de teléfono no puede tener más de 9 dígitos",
    },
  ];

  function passportValidator(rule, value, callback) {
    console.log("value", value);
    const pattern = /^[A-Za-z0-9]$/;
    if (!value) {
      return callback("Se requiere un número de pasaporte");
    }
    console.log("pattern.test(value)", pattern.test(value));
    if (!pattern.test(value)) {
      return callback("Formato inválido, sólo se permiten números y letras");
    }
    // if (value.length < 6 || value.length > 15) {
    //   return callback(
    //     "Número de pasaporte debe contener entre 6 y 15 caracteres"
    //   );
    // }
    return callback();
  }

  const rulesPassport = [
    {
      pattern: new RegExp(/^[a-zA-Z0-9]*$/),
      message: "Formato inválido, sólo se permiten números y letras",
    },
    {
      required: true,
      message: "Se requiere un número de pasaporte",
    },
    { min: 6, max: 15, message: "Debe contener entre 6 y 15 carácteres" },
  ];

  const validateEmail = (rule, value, callback) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (value && !emailRegex.test(value)) {
      callback("Debes ingresar un correo válido");
    } else {
      callback();
    }
  };

  const onClose = () => {
    setShowAlert(false);
  };
  if (isSpeciesLoading) {
    return (
      <div style={{ display: "grid", placeItems: "center", height: "100vh" }}>
        <Spin />
      </div>
    );
  }

  const changeSpecie = (specieCode) => {
    if (errorSpecie === "block") {
      setErrorSpecie("none");
    }
    setSpecieType(specieCode);
  };

  const CREAR_COLAB = userHasPermission(rolData?.role, COLABORADORES.CREAR);

  if (!CREAR_COLAB) {
    return <Navigate to="/first-login" state={{ unauthorizedError: true }} />;
  }

  return (
    <div className="crear-usuario">
      <AlertModal
        show={showAlert}
        onClose={onClose}
        type={alertType}
        title={alertTitle}
        errorMessages={errors}
      >
        {alertMessage}
      </AlertModal>
      <ConfigProvider locale={es_ES}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
          requiredMark={false}
        >
          <div className="main-form">
            <div className="main-form-fields">
              <Row>
                <Col span={24}>
                  <h4 className="form-title">
                    Ingresa los datos del colaborador en los siguientes campos:
                  </h4>
                </Col>

                <Divider style={{ marginTop: "0" }} />
              </Row>
              <Row gutter={32}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    label="Selecciona el documento identificador del colaborador"
                    rules={rules}
                  >
                    <Radio.Group
                      defaultValue={1}
                      onChange={(e) => {
                        setRadioValue(e.target.value);
                        form.setFieldsValue({ rut: "", passport: "" });
                      }}
                    >
                      <Radio value={1}>RUT</Radio>
                      <Radio value={0}>Pasaporte</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                {radioValue === 1 ? (
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Form.Item
                      label="RUT"
                      name="rut"
                      rules={rulesRut}
                      validateTrigger="onBlur"
                    >
                      <Input onChange={handleChange} maxLength={12} />
                    </Form.Item>
                  </Col>
                ) : (
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Form.Item
                      label="Pasaporte"
                      name="passport"
                      // rules={[rules, { validator: passportValidator }]}
                      rules={rulesPassport}
                    >
                      <Input maxLength={15} minLength={6} />
                    </Form.Item>
                  </Col>
                )}
              </Row>
              <Row gutter={32}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item label="Nombres" name="name" rules={rules}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item label="Apellidos" name="lastname" rules={rules}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    label="Tipo de colaborador"
                    name="type_personal_id"
                    rules={rules}
                  >
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={
                        personalData &&
                        personalData.map((item) => ({
                          value: parseInt(item.id),
                          label: capitalize(item.name_type_personal),
                          key: parseInt(item.id),
                        }))
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    label="Cargo del colaborador"
                    name="position_id"
                    rules={rules}
                  >
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={cargoColaborador.map((item) => ({
                        value: item.id,
                        label: item.name,
                        key: item.id,
                      }))}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    label="Nacionalidad"
                    name="nationality_id"
                    rules={rules}
                  >
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={
                        nationalityData &&
                        nationalityData.map((item) => ({
                          value: parseInt(item.id),
                          label: item.name,
                          key: parseInt(item.id),
                        }))
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item label="Género" name="gender_id" rules={rules}>
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={
                        genderData &&
                        genderData.map((item) => ({
                          value: parseInt(item.id),
                          label: capitalize(item.name),
                          key: parseInt(item.id),
                        }))
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Input.Group size="large">
                    <Row gutter={windowWidth > 1024 ? 48 : 16}>
                      <Col span={8}>
                        <Form.Item
                          label="Prefijo"
                          name="phonePrefix"
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          validateTrigger="onBlur"
                        >
                          <Select
                            showSearch
                            style={{
                              width: "100%",
                            }}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.label ?? "").toLowerCase()
                                )
                            }
                            options={
                              sortedCodesWithLabel &&
                              sortedCodesWithLabel.map((item) => ({
                                value: item.dial_code,
                                label: `+${item.dial_code}`,
                                title: item.name,
                                key: item.name,
                              }))
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={16}>
                        <Form.Item
                          label="Número telefónico"
                          name="phone_number"
                        >
                          <Input style={{ height: "36px" }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Input.Group>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    label="Correo electrónico"
                    name="email"
                    rules={[
                      { required: false, message: "Debes ingresar un correo" },
                      { validator: validateEmail },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    label="Rut de empresa"
                    name={["company", "rut"]}
                    rules={rulesRut}
                    validateTrigger="onBlur"
                  >
                    <Input
                      disabled={disableSocialReasonCompany}
                      onChange={handleChangeCompanyRut}
                      type="text"
                      maxLength={12}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    label="Razón social"
                    name={["company", "social_reason"]}
                    rules={rules}
                    onChange={handleChangeCompanySocialReason}
                  >
                    <Input disabled={disableCompany} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Input.Group size="large">
                    <Row gutter={windowWidth > 1024 ? 48 : 16}>
                      <Col span={8}>
                        <Form.Item label="Prefijo" name="companyPhonePrefix">
                          <Select
                            showSearch
                            style={{
                              width: "100%",
                            }}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.label ?? "").toLowerCase()
                                )
                            }
                            options={
                              sortedCodesWithLabel &&
                              sortedCodesWithLabel.map((item) => ({
                                value: item.dial_code,
                                label: `+${item.dial_code}`,
                                title: item.name,
                                key: item.name,
                              }))
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={16}>
                        <Form.Item
                          label="Teléfono de empresa"
                          name={["company", "phone_company"]}
                          rules={rulesCompanyPhone}
                        >
                          <Input style={{ height: "36px" }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Input.Group>
                </Col>
              </Row>
            </div>

            <Divider />

            <div className="main-form-dates">
              <Row gutter={32}>
                <Col span={24}>
                  <h4 className="form-title">Vigencia del contrato</h4>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    label="Inicio - Término"
                    name="inicioTermino"
                    rules={rules}
                  >
                    <RangePicker
                      locale={es_ES}
                      onChange={selectDate}
                      format="DD/MM/YYYY"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div className="main-form-permissions">
              <Row gutter={32}>
                <Col span={24}>
                  <h4 className="form-title">
                    Asignar permisos al colaborador
                  </h4>
                </Col>
              </Row>
              <Divider style={{ marginTop: 0 }} />
              <Row gutter={32}>
                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                  <div className="main-form-permission-buttons">
                    {specieTypeBtns.map((specieTypeBtn) => (
                      <button
                        key={specieTypeBtn.id}
                        type={specieTypeBtn.btnType}
                        className={`action-btn ${specieType === specieTypeBtn.value
                            ? "active"
                            : "inactive"
                          }`}
                        onClick={() => changeSpecie(specieTypeBtn.value)}
                      >
                        {specieTypeBtn.btnText}
                      </button>
                    ))}
                  </div>
                </Col>
              </Row>
              <Row gutter={32} className="permissions">
                <Col
                  span={24}
                  className={`${selectedDates && specieType === "cerdo" ? "show" : "hide"
                    }`}
                >
                  <PermissionsAccess
                    storage={getCerdoData}
                    payload={cerdoData}
                    form={form}
                    date_start={selectedDates.date_start}
                    date_end={selectedDates.date_end}
                    code={species.cerdos.code}
                    idSpecie={species.cerdos.id}
                  />
                </Col>

                <Col
                  span={24}
                  className={`${selectedDates && specieType === "pollo" ? "show" : "hide"
                    }`}
                >
                  <PermissionsAccess
                    storage={getPolloData}
                    payload={polloData}
                    form={form}
                    date_start={selectedDates.date_start}
                    date_end={selectedDates.date_end}
                    code={species.pollos.code}
                    idSpecie={species.pollos.id}
                  />
                </Col>

                <Col
                  span={24}
                  className={`${selectedDates && specieType === "pavo" ? "show" : "hide"
                    }`}
                >
                  <PermissionsAccess
                    storage={getPavoData}
                    payload={pavoData}
                    form={form}
                    date_start={selectedDates.date_start}
                    date_end={selectedDates.date_end}
                    code={species.pavos.code}
                    idSpecie={species.pavos.id}
                  />
                </Col>

                <Col
                  span={24}
                  className={`${selectedDates && specieType === "alimento" ? "show" : "hide"
                    }`}
                >
                  <PermisosPlantas
                    storage={getAlimentoData}
                    payload={alimentosData}
                    form={form}
                    date_start={selectedDates.date_start}
                    date_end={selectedDates.date_end}
                    code={species.plantaalimentos.code}
                    id={species.plantaalimentos.id}
                  />
                </Col>

                <span style={{ display: `${errorSpecie}`, color: "red" }}>
                  Debe seleccionar al menos una especie
                </span>
              </Row>
            </div>
          </div>
          <div className="form-buttons">
            <PrimaryButton type="submit" isLoading={isLoading} width={300}>
              Crear usuario
            </PrimaryButton>
          </div>
        </Form>
      </ConfigProvider>
    </div>
  );
};

export default CrearUsuario;
